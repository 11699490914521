<template>
    <h1 class="mb-5 text-center">Har heisen stoppet opp?</h1>
    <p class="font-weight-bold text-center">
        Sørg for at dere har gått igjennom
        <router-link to="/support/sjekkliste_ved_stopp">
        sjekklista ved stopp 
        </router-link>
        for å utelukke de vanligste årsakene.
    </p>
    <!--
    <p>
        All service og vedlikehold utføres av <a href="https://rajelektro.no/">RAJ Elektro</a>.
    </p>
    <p>
        RAJ Elektro er kvalifisert Temco Vareheiser partner og har full kompentanse og erfaring innen vareheiser fra Temco Vareheiser.
    </p>
    <a href="https://rajelektro.no/">
        <img class="img-fluid" src="@/assets/images/logo-raj-web-transparent.png"> 
    </a>
    -->
</template>
<script>
export default {
    name: "Support",

}
</script>>